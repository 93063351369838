<script lang="ts">
	import { cn } from '../../../utils/cn';
	import { createNoise3D } from 'simplex-noise';
	import { onMount } from 'svelte';
	import { writable } from 'svelte/store';

	export let className: string | undefined = undefined;
	export let containerClassName: string | undefined = undefined;
	export let colors: string[] | undefined = undefined;
	export let waveWidth: number | undefined = undefined;
	export let blur: number | undefined = 10;
	export let speed: 'slow' | 'fast' | undefined = 'fast';
	export let waveOpacity: number | undefined = 0.5;

	let backgroundFill = writable('transparent');
	const noise = createNoise3D();
	let w: number, h: number, nt: number, i: number, x: number, ctx: any, canvas: any;
	let canvasRef: HTMLCanvasElement;

	const isMobile = () => window.innerWidth < 768;  // Common breakpoint for mobile devices

	const updateBackgroundFill = () => {
		const theme = localStorage.getItem('theme') || 'light';
		backgroundFill.set(theme === 'dark' ? '#1e2126' : '#ffffff');
	};

	const getSpeed = () => {
		switch (speed) {
			case 'slow':
				return 0.001;
			case 'fast':
				return 0.002;
			default:
				return 0.001;
		}
	};

	const init = () => {
		if (isMobile()) return;  // Do not initialize the animation if on mobile

		canvas = canvasRef;
		ctx = canvas.getContext('2d');
		w = ctx.canvas.width = window.innerWidth;
		h = ctx.canvas.height = window.innerHeight;
		ctx.filter = `blur(${blur}px)`;
		nt = 0;
		window.onresize = function () {
			w = ctx.canvas.width = window.innerWidth;
			h = ctx.canvas.height = window.innerHeight;
			ctx.filter = `blur(${blur}px)`;
		};
		render();
	};

	const waveColors = colors ?? ['#38bdf8', '#818cf8', '#c084fc', '#e879f9', '#22d3ee'];
	const drawWave = (n: number) => {
		if (isMobile()) return;  // Skip drawing waves if on mobile

		nt += getSpeed();
		for (i = 0; i < n; i++) {
			ctx.beginPath();
			ctx.lineWidth = waveWidth || 50;
			ctx.strokeStyle = waveColors[i % waveColors.length];
			for (x = 0; x < w + 10; x += 5) {  // Extend drawing slightly beyond canvas width
				var y = noise(x / 800, 0.3 * i, nt) * 100;
				ctx.lineTo(x, y + h * 0.5);
			}
			ctx.stroke();
			ctx.closePath();
		}
	};

	let animationId: number;
	const render = () => {
		if (isMobile()) return;  // Prevent rendering if on mobile

		ctx.clearRect(0, 0, w, h);  // Clear the entire canvas before redrawing
		ctx.fillStyle = $backgroundFill;
		ctx.globalAlpha = waveOpacity || 0.5;
		ctx.fillRect(0, 0, w, h);
		drawWave(5);
		animationId = requestAnimationFrame(render);
	};

	onMount(() => {
		updateBackgroundFill();
		init();
		document.addEventListener('themeChanged', updateBackgroundFill);
		return () => {
			cancelAnimationFrame(animationId);
			document.removeEventListener('themeChanged', updateBackgroundFill);
		};
	});
</script>

<div class={cn('flex h-screen flex-col items-center justify-center overflow-hidden', containerClassName)}>
	<canvas class="absolute inset-0 z-0" bind:this={canvasRef} id="canvas"></canvas>
	<div class={cn('relative z-10', className)} {...$$props}>
		<slot />
	</div>
</div>
